<template>
  <!-- showGiveTipsMoreInfo -->
  <div class="mediaSelectDesktop">
    <!-- Bootstrap, though loaded first, was overriding some unknown style element, hard to solve out, which was sending the login page to top right. Rather than waste a long time trying to find out which, I am only applying Bootstrap to pages which use it -->
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css"
      integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH"
      crossorigin="anonymous"
    />

    <div
      class="container col-12 offset-0 col-md-6 offset-md-3 tugradient1"
      style="padding-top: 5vh; padding-bottom: 5vh: outline:0.01em dashed red"
    >
      <table
        width="100%"
        border="0"
        style="
          margin: auto;
          outline: 0em solid blue;
          padding-left: 2.5vh;
          padding-right: 2.5vh;
        "
      >
        <tr>
          <td>
            <table width="100%" border="0">
              <tr>
                <td width="30%">
                  <div class="divl">&nbsp;</div>
                </td>
                <td width="40%">
                  <a
                    class="navbar-brand"
                    style="outline: 0em dashed blue"
                    href="#"
                    ><img
                      class="tunavbarlogoheight"
                      alt="thankU logo"
                      src="../assets/tutxtlogomd.webp"
                  /></a>
                </td>
                <td width="30%">
                  <div class="divr">
                    <button
                      @click="this.doGoBack()"
                      class="text-only-button cancel"
                    >
                      <img
                        class="tulogo"
                        style="height: 4vh"
                        alt="close"
                        src="../assets/greycross.png"
                      />
                    </button>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <div>
        <MoreInfoItem
          :ListItemString1="this.isThereAFee"
          :ListItemString2="this.isThereAFeeInfo"
        />
        <MoreInfoItem
          :ListItemString1="this.isTippingSecure"
          :ListItemString2="this.isTippingSecureInfo"
        />
        <MoreInfoItem
          :ListItemString1="this.willTheRecipientKnow"
          :ListItemString2="this.willTheRecipientKnowInfo"
        />
        <MoreInfoItem
          :ListItemString1="this.whatHappensNextTime"
          :ListItemString2="this.whatHappensNextTimeInfo"
        />
        <MoreInfoItem
          :ListItemString1="this.tipWithoutScanningOption"
          :ListItemString2="this.tipWithoutScanningOptionInfo"
        />
        <MoreInfoItem
          :ListItemString1="this.getReceipt"
          :ListItemString2="this.getReceiptInfo"
        />
        <MoreInfoItem
          :ListItemString1="this.tipHistory"
          :ListItemString2="this.tipHistoryInfo"
        />
      </div>
      <table width="100%" border="0" style="padding-bottom: 7vw">
        <tr>
          <td width="100%" class="divc">
            <div class="divc" style="padding-top: 2vw; padding-bottom: 1vw">
              <a
                href="mailto:hello@thanku.app?subject=thankU enquiry from giving tips screen&amp;body=How can we help?"
                class="text-only-button cancel"
                >Email us your question</a
              >
            </div>
          </td>
        </tr>
         <tr>
          <td>
            <div class="actionbtn-div" style="padding-top: 1vh">
              <button
                class="widebtn"
                style="width: 20%; height: 6vh"
                @click="this.doGoBack()"
              >
                Ok
              </button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <!-- showGiveTipsMoreInfo -->
  <div
    id="topdiv"
    class="modal-overlay-canscroll mediaSelectPhone"
    style="position: absolute; top: 10px; width: 100%; outline: 0em solid pink"
  >
    <div
      class="modalnotbootstrap mediaSelectPhone"
      style="
        outline: 0em solid pink;
        padding-top: 5vh;
        padding-bottom: 5vh;
        padding-left: 5vw;
        padding-right: 5vw;
      "
    >
      <table
        width="100%"
        border="0"
        style="
          margin: auto;
          outline: 0em solid blue;
          padding-left: 2.5vw;
          padding-right: 2.5vw;
        "
      >
        <tr>
          <td>
            <table width="100%" border="0">
              <tr>
                <td width="40%">
                  <div class="divl">
                    <button
                      class="text-only-button cancel"
                      @click="this.toggleAll"
                      style="display: block; line-height: 15px"
                    >
                      {{ toggleButtonText }}
                    </button>
                  </div>
                </td>
                <td width="30%"></td>
                <td width="30%">
                  <div class="divr">
                    <button
                      @click="this.doGoBack()"
                      class="text-only-button cancel"
                    >
                      <img
                        class="tulogo"
                        style="height: 4vh"
                        alt="close"
                        src="../assets/greycross.png"
                      />
                    </button>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">
            <div class="actionbtn-div" style="padding-top: 1vh">
              <button
                @click="this.showFeeInfo = !this.showFeeInfo"
                class="infobtn turegularfontsize tufont"
                style="width: 100%; height: 9vh;"
              >
                {{ this.isThereAFee }} 
              </button>
            </div>
            <div
              v-show="showFeeInfo"
              class="actionbtn-div turegularfontsize tufont"
              v-html="this.isTippingSecureInfo"
            ></div>
          </td>
        </tr>
        <tr>
          <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">
            <div class="actionbtn-div" style="padding-top: 1vh">
              <button
                @click="this.showSecureInfo = !this.showSecureInfo"
                class="infobtn turegularfontsize tufont"
                style="width: 100%; height: 9vh;"
              >
                {{ this.isTippingSecure }}
              </button>
            </div>
            <div
              v-show="showSecureInfo"
              class="actionbtn-div turegularfontsize tufont"
              v-html="this.isTippingSecureInfo"
            ></div>
          </td>
        </tr>
        <tr>
          <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">
            <div class="actionbtn-div" style="padding-top: 1vh">
              <button
                @click="this.showWhoTipped = !this.showWhoTipped"
                class="infobtn turegularfontsize tufont"
                style="width: 100%; height: 9vh;"
              >
                {{ this.willTheRecipientKnow }}
              </button>
            </div>
            <div
              v-show="showWhoTipped"
              class="actionbtn-div turegularfontsize tufont"
              v-html="this.willTheRecipientKnowInfo"
            ></div>
          </td>
        </tr>
        <tr>
          <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">
            <div class="actionbtn-div" style="padding-top: 1vh">
              <button
                @click="this.showCardNextTime = !this.showCardNextTime"
                class="infobtn turegularfontsize tufont"
                style="width: 100%; height: 9vh;"
              >
                {{ this.whatHappensNextTime }}
              </button>
            </div>
            <div
              v-show="showCardNextTime"
              class="actionbtn-div turegularfontsize tufont"
              v-html="this.whatHappensNextTimeInfo"
            ></div>
          </td>
        </tr>
        <tr>
          <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">
            <div class="actionbtn-div" style="padding-top: 1vh">
              <button
                @click="
                  this.showTipWithoutScanningOption =
                    !this.showTipWithoutScanningOption
                "
                class="infobtn turegularfontsize tufont"
                style="width: 100%; height: 9vh;"
              >
                {{ this.tipWithoutScanningOption }}
              </button>
            </div>
            <div
              v-show="showTipWithoutScanningOption"
              class="actionbtn-div turegularfontsize tufont"
              v-html="this.tipWithoutScanningOptionInfo"
            ></div>
          </td>
        </tr>
        <tr>
          <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">
            <div class="actionbtn-div" style="padding-top: 1vh">
              <button
                @click="this.showGetReceipt = !this.showGetReceipt"
                class="infobtn turegularfontsize tufont"
                style="width: 100%; height: 9vh;"
              >
                {{ this.getReceipt }}
              </button>
            </div>
            <div
              v-show="showGetReceipt"
              class="actionbtn-div turegularfontsize tufont"
              v-html="this.getReceiptInfo"
            ></div>
          </td>
        </tr>
        <tr>
          <td style="padding-top: 0.5vh; padding-bottom: 0.5vh">
            <div class="actionbtn-div" style="padding-top: 1vh">
              <button
                @click="this.showGetTipHistory = !this.showGetTipHistory"
                class="infobtn turegularfontsize tufont"
                style="width: 100%; height: 9vh;"
              >
                {{ this.tipHistory }}
              </button>
            </div>
            <div
              v-show="showGetTipHistory"
              class="actionbtn-div turegularfontsize tufont"
              v-html="this.tipHistoryInfo"
            ></div>
          </td>
        </tr>
        <tr>
          <td>
            <table width="100%" border="0">
              <tr>
                <td width="100%" class="divc">
                  <div class="divc" style="padding-top: 1.5vh">
                    <a
                      href="mailto:hello@thanku.app?subject=thankU enquiry from giving tips screen&amp;body=How can we help?"
                      class="text-only-button cancel"
                      >Email us your question</a
                    >
                    <!-- <button class="text-only-button cancel" @click="emailThankU" >Email us your question</button> -->
                  </div>
                </td>
              </tr>
               <tr>
                <td>
                  <div class="actionbtn-div" style="padding-top: 2vh">
                    <button
                      class="widebtn"
                      style="width: 20%; height: 6vh"
                      @click="this.doGoBack()"
                    >
                      Ok
                    </button>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

import shared from "../shared.js";

const MoreInfoItem = defineAsyncComponent({
  loader: () => import("../components/MoreInfoItem.vue"),
});

export default {
  inject: ["globalPushForwardInterval", "devEnv", "appId", "appJSKey"],
  data() {
    return {
      showFeeInfo: false,
      showSecureInfo: false,
      showWhoTipped: false,
      showCardNextTime: false,
      showTipWithoutScanningOption: false,
      showGetReceipt: false,
      showGetTipHistory: false,
      fromScreen: undefined,
      toggleButtonText: "Expand All",
      isThereAFee: "Is there a fee to use thankU?",
      isThereAFeeInfo:
        "Fees for using thankU are charged to the person you are tipping by deducting fees from tips - unless you choose to add them onto your tip so that the person you are tipping gets the full tip. You are given the option to do this before you confirm payment.",
      isTippingSecure: "Is tipping with thankU secure?",
      isTippingSecureInfo:
        "Yes - thankU's default payment methods are Apple Pay or Google Pay and thankU uses Stripe as its payment processor. Stripe processes about 15% of all transactions on the internet, and likely rather more in payment-enabled phone apps. Stripe processes payments for millions of business including Amazon, Deliveroo, Booking.com and ASOS - <a href='https://stripe.com/en-gb/customers' target=blank>see some Stripe customers</a> <p></p>If you don't use Apple Pay or Google Pay, tipping with your card is still just as secure - because it uses the same kind of process, converting your card details into a random string of letters and numbers, called a digital secure token.",
      willTheRecipientKnow: "Will the recipient know who tipped them?",
      willTheRecipientKnowInfo:
        "When you give your first tip with thankU, at the end of the process you will be asked if you would like to let the recipient know who tipped them. If you would, then you tap in e.g. your first name or whatever you wish, and from then on that's what anyone you tip will see - that is the only piece of information which is shared with them. For example, your email address is NEVER shared with them.<p></p>If you choose not to supply this info, then your recipient's tip receipt will simply say 'name not given'.",
      whatHappensNextTime: "If I pay by card, what happens next time I tip?",
      whatHappensNextTimeInfo:
        "When you pay by card, there is a tick box marked 'Save securely for next time'. If you tick the box, thankU saves a secure digital token (not your card details) which means next time you tip, you don't need to enter your card details again.<p></p>If you do not tick the box, your secure digital token will not be saved and you will be required to enter the details again next time you use thankU.",
      tipWithoutScanningOption: "What is the 'tip without scanning' option?",
      tipWithoutScanningOptionInfo:
        "Normally, you tip with thankU by scanning the thankU QR code badge at the place you want to tip, e.g. your hair salon. However, if you want to leave a tip after you have left, simply tap the 'Tip without scanning' button, find the business where you want to tip in the list displayed, then select the person at that business you want to tip, and tip them as normal. ",
      getReceipt: "Can I get a payment receipt from thankU?",
      getReceiptInfo:
        "Yes you can - at the end of your first tip, you will be asked if you want a receipt - if you do, just provide your email address, and thankU will send you a receipt for that tip and all your future tips. <p></p> If you want, you can supply your email later through the account screen but you can only get receipts for tips made from the point in time when you supplied your email address, though full details will always be available in your Transaction History, which you can also have emailed to you at any time.",
      tipHistory: "Can I get a history of the tips I've given?",
      tipHistoryInfo:
        "Yes you can - click on the account icon to go into your account page and select Transaction History from there. If you have supplied your email address we can also email you Transaction History reports.",
    };
  },
  components: {
    MoreInfoItem,
  },
  methods: {
    toggleAll() {
      // /console.log("toggling...");

      var status = false;

      if (this.toggleButtonText === "Expand All") {
        this.toggleButtonText = "Collapse All";
        status = true;
      } else {
        this.toggleButtonText = "Expand All";
        status = false;
      }

      this.showFeeInfo = status;
      this.showSecureInfo = status;
      this.showWhoTipped = status;
      this.showCardNextTime = status;
      this.showTipWithoutScanningOption = status;
      this.showGetReceipt = status;
      this.showGetTipHistory = status;
    },
    doGoBack(){
      console.log("this.fromScreen: " + this.fromScreen);
      if(this.fromScreen !== undefined) {
        let returnJSON = { [this.fromScreen]: true };
        console.log(JSON.stringify(returnJSON, null, 2));
        this.$emit("return-json", returnJSON);
      } else  {
        let returnJSON = { showHomeNew: true };
        this.$emit("return-json", returnJSON);
      }
    },
    // goBack() {
    //   // /console.log("we're off!");
    //   if (window.localStorage.getItem("nextScreen") !== null) {
    //     let returnJSON = {
    //       nextScreen: window.localStorage.getItem("nextScreen"),
    //     };
    //     this.$emit("return-json", returnJSON);
    //     window.localStorage.removeItem("nextScreen");
    //   } else {
    //     let returnJSON = { showHomeNew: true };
    //     this.$emit("return-json", returnJSON);
    //   }
    // },
    doSetMetas() {
      document.title =
        "All your questions answered on giving tips with thankU cashless tipping, used at top salons such as Larry King and Josh Wood Colour. ";
      document
        .getElementsByTagName("meta")
        .namedItem("description")
        .setAttribute(
          "content",
          "All your questions answered on giving tips with thankU cashless tipping, used at top salons such as Larry King and Josh Wood Colour. Questions like: " +
            this.isThereAFee +
            " " +
            this.isTippingSecure +
            " " +
            this.willTheRecipientKnow +
            " " +
            this.whatHappensNextTime +
            " " +
            this.tipWithoutScanningOption +
            " " +
            this.getReceipt +
            " " +
            this.tipHistory +
            " "
        );
    },
  },
  created() {
    document.body.style.maxWidth = "100vw"; // for Google mobile crawler had to limit homepage width on body tag, this resets it
    this.doSetMetas();
    shared.saveToUserPath(
      this.devEnv,
      "Give tips more info " + new Date().toISOString(),
      this.globalPushForwardInterval
    );
    this.fromScreen = (this.$route.params !== undefined && this.$route.params.fromScreen !== undefined) ? this.$route.params.fromScreen : undefined; // so showHomePageFromGoogleAd is not passed as a prop, it is passed through this.$route.params AND NOTE: though this.showHomePageFromGoogleAd is passed as a boolean from Homecontent passing it through the router converts the value to a string
  },
};
</script>